import { Breakpoints } from '../settings'
const { MOBILE, TABLET, DESKTOP } = Breakpoints

export const deriveBreakpoint = width => {
  // If width is less than or equal to the device max width, use that device.
  // Compare against smallest devices first
  if (MOBILE.max >= width) {
    return MOBILE.name
  } else if (TABLET.max >= width) {
    return TABLET.name
  } else {
    return DESKTOP.name
  }
}
