import React from 'react'
import { deriveBreakpoint } from '../helpers'
import { isWindow } from '../helpers/isWindow'
export { Breakpoints } from '../settings'

export const BreakpointContext = React.createContext(
  deriveBreakpoint(isWindow() ? window.innerWidth : 144)
)

export const ScrollContext = React.createContext()
