import { css } from '@emotion/react'
import { spacing } from '../settings/postBodyConstants'

export const postBodyStyles = (theme) => css`
  & > :last-child {
    margin-bottom: 0;
  }
  & > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(&:first-child) {
      margin-top: 2em;
    }
    &:not(&:last-child) {
      margin-bottom: 0.75em;
    }
  }

  b,
  p,
  a,
  b,
  i,
  u {
    line-height: 1.6rem;
    margin: 0 0 ${spacing.one} 0;
    display: block;
    b,
    p,
    a,
    b,
    i,
    u {
      display: inline;
    }
  }

  * > {
    b,
    p,
    a,
    b,
    i,
    u {
      line-height: 1.6rem;
      margin: 0;
    }
  }

  a {
    transition: 0.2s;
    color: ${theme.textColour};
    &:hover {
      color: ${theme.accentColour};
    }
  }

  del {
    text-decoration: line-through;
  }

  strong,
  b {
    font-weight: 600;
  }

  em,
  i {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 ${spacing.two} 0;
    ul,
    ol {
      padding-left: 1em;
      margin-bottom: 0;
      ul,
      ol {
        padding-left: ${spacing.two};
        margin-bottom: 0;
      }
    }
    p {
      display: inline;
    }
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
      li {
        list-style: circle;
        list-style-position: inside;
        li {
          list-style: square;
        }
      }
    }
  }

  ol {
    ol {
      list-style-type: lower-latin;
    }
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
      li {
        list-style: upper-roman;
        list-style-position: inside;
        li {
          list-style: lower-roman;
        }
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${theme.accentColour};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${theme.accentColour};
    padding: 0.75em;
    margin: 1em 0;
    p:not(p:last-of-type) {
      margin-bottom: 1em;
    }
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${theme.accentColour} !important;
    span {
      background: inherit !important;
    }
  }
`
