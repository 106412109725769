import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import { ScrollContext } from '../contexts'

const ScrollProvider = props => {
  const [scrollY, setScrollY] = useState(0)

  const scrollHandler = throttle(() => setScrollY(window.scrollY), 100)

  useEffect(() => {
    window.scrollTo(0, scrollY)
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])
  return (
    <ScrollContext.Provider value={scrollY}>
      {props.children}
    </ScrollContext.Provider>
  )
}

ScrollProvider.propTypes = {
  children: PropTypes.object,
}

export default ScrollProvider
