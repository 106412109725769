import { css } from '@emotion/react'
import { mediaQuery } from '../styles/mediaQuery'

export const computeGridMargins = ({ config }, breakpoint) => {
  const grid = config[breakpoint]
  return (grid.margins / grid.screen) * 100
}

const deriveGridSizing = (grid, breakpoint) => {
  const device = grid.config[breakpoint]
  const computedMargins = computeGridMargins(grid, breakpoint)
  const wrapperWidth = device.screen - device.margins * 2
  const avgGutterWidth = (device.gutter / wrapperWidth) * 100
  const avgColumnWidth = (wrapperWidth / device.columns / wrapperWidth) * 100
  return { computedMargins, wrapperWidth, avgGutterWidth, avgColumnWidth }
}

export const computeColumnGap = (
  grid,
  breakpoint,
  columnGap,
  isLast = false
) => {
  const { avgGutterWidth, avgColumnWidth } = deriveGridSizing(grid, breakpoint)
  const computedWidth = avgColumnWidth * columnGap + avgGutterWidth
  if (isLast) return columnGap ? computedWidth : 0
  return columnGap ? computedWidth : avgGutterWidth
}

export const computeWidthFromColumns = (grid, breakpoint, columns, gap = 0) => {
  const { avgGutterWidth, avgColumnWidth } = deriveGridSizing(grid, breakpoint)
  const computedWidth = avgColumnWidth * columns - avgGutterWidth / 2

  return css`
    width: ${computedWidth}%;
    /* border: 1px solid mediumspringgreen; */
    margin-right: ${computeColumnGap(grid, breakpoint, gap)}%;
    &:last-of-type {
      margin-right: ${computeColumnGap(grid, breakpoint, gap, true)}%;
    }
    ${mediaQuery.TABLET} {
      margin-top: 30px;
      margin-right: 0;
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    ${mediaQuery.MOBILE} {
      width: 100%;
      margin-top: 30px;
      margin-right: 0;
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  `
}

export const computeWidthValue = (grid, breakpoint, columns) => {
  const device = grid.config[breakpoint]
  const fullWidth = Math.floor(
    ((device.screen - device.margins * 2) * 100) / device.screen
  )
  const columnWidth = Math.floor((fullWidth / device.columns) * columns)
  const computedWidth =
    device.columns > columns ? `${columnWidth}vw` : `${fullWidth}vw`
  return computedWidth
}

/**
 * @description prefer computeViewportWidthFromColumns over computeWidthFromColumns when the element occupies its own row
 */
export const computeViewportWidthFromColumns = (grid, breakpoint, columns) => {
  const computedWidth = columns
    ? computeWidthValue(grid, breakpoint, columns)
    : '100%'
  return css`
    width: ${computedWidth};
  `
}
