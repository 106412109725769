export const { MOBILE, TABLET, DESKTOP } = Object.freeze({
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
})

/**
 * @description If max is >= screen size, use <DEVICE> breakpoint
 */
export const Breakpoints = Object.freeze({
  [MOBILE]: { max: 767, name: MOBILE },
  [TABLET]: { min: 768, max: 1023, name: TABLET },
  [DESKTOP]: { min: 1024, max: Infinity, name: DESKTOP },
})
